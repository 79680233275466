import { render, staticRenderFns } from "./folder-detail.vue?vue&type=template&id=cdf5e95a&scoped=true&"
import script from "./folder-detail.vue?vue&type=script&lang=ts&"
export * from "./folder-detail.vue?vue&type=script&lang=ts&"
import style0 from "./folder-detail.vue?vue&type=style&index=0&id=cdf5e95a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdf5e95a",
  null
  
)

export default component.exports