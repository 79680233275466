import { RouteConfig } from 'vue-router';
const Zhanghu = () => import('./zhanghu/zhanghu.vue');
const Juese = () => import('./juese/juese.vue');
const Apply = () => import('./apply/apply.vue');
const Info = () => import('./info/info.vue');
const Material = () => import('./material/material.vue');
import ZhanghuRouterChildren from './zhanghu/zhanghu.router';
import JueseRouterChildren from './juese/juese.router';
import ApplyRouterChildren from './apply/apply.router';
import InfoRouterChildren from './info/info.router';
import MaterialRouterChildren from './material/material.router';
const routers: RouteConfig[] = [
    {
        path: 'zhanghu',
        component: Zhanghu,
        children: ZhanghuRouterChildren
    },
    {
        path: 'juese',
        component: Juese,
        children: JueseRouterChildren
    },
    {
        path: 'apply',
        component: Apply,
        children: ApplyRouterChildren
    },
    {
        path: 'info',
        component: Info,
        children: InfoRouterChildren
    },
    {
        path: 'material',
        component: Material,
        children: MaterialRouterChildren
    },
    {
        path: '*',
        redirect: 'zhanghu'
    },
];
export default routers;

