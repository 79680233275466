



















import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { PostBookPrimarySearch } from "@/request/common";
import { GetIdArr } from "@/utils/base";
@Component({})
export default class Name extends mixins(listMixin) {
  private pendding: any = false;
  private searchData: any = {};
  private data: any = [];
  private cTab: any = {};
  private getText(item: any) {
    let str: any = "";
    if (item["书名"]) {
      str += item["书名"];
    }
    if (item["父章节列表"] && item["父章节列表"].length > 0) {
      str += item["父章节列表"].join('-');
    }
    if (item["章节"]) {
      str += "-" + item["章节"];
    }
    if (item["章节名"]) {
      str += "-" + item["章节名"];
    }
    return str;
  }
  private changeTab(item: any) {
    this.cTab = item;
    this.$emit("changeCData", item);
  }
  getList(part?: any) {
    // part 存在表示是触底加载下一页
    if (part) {
      if (this.filters.current_page === this.filters.total_page) {
        return;
      }
      this.filters.current_page++;
    }
    const params: any = {
      检索内容: this.searchData["检索内容"],
      书籍列表: this.searchData["古籍名称"],
      current_page: this.filters.current_page,
      page_num: 50,
      朝代: GetIdArr(this.searchData["朝代"] || [], "_id"),
      作者: GetIdArr(this.searchData["作者"] || [], "_id"),
      古籍分类: GetIdArr(this.searchData["古籍分类"] || [], "id"),
      校验状态: this.searchData["校验状态"],
      是否需要校验: this.searchData["是否校验"],
      来源: this.searchData["来源"],
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostBookPrimarySearch(this, params, loading).then((data: any) => {
      loading.close();
      this.pendding = false;
      if (part) {
        this.data = this.data.concat(data.contents);
      } else {
        this.data = data.contents;
      }
      this.filters.total_count = data.total_count;
      this.filters.total_page = data.total_page;
      if (this.data.length === 0) {
        this.ifShowEmpty = true;
      } else {
        this.ifShowEmpty = false;
      }
      this.$emit("changeNavData", data);
      if ((!this.cTab || !this.cTab._id) && this.data.length > 0) {
        this.changeTab(this.data[0]);
      }
    });
  }
  mounted() {
    if (this.$route.query.search) {
      this.searchData = JSON.parse(this.$route.query.search as any);
      this.getList();
    }
    const el: any = document.querySelector(".left-list-wrap");
    el.onscroll = () => {
      const scrollTop = el.scrollTop;
      const scrollHeight = el.scrollHeight;
      const H: any = document.body.clientHeight - 10 - 10 - 50;
      if (H + scrollTop - scrollHeight >= -20) {
        if (!this.pendding) {
          this.pendding = true;
          this.getList("next");
        }
      }
    };
  }
}
