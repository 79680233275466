





























































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import echarts from "echarts";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import {
  GetShitiList,
  PostBookContent,
  GetShitiNav,
  GetYuyileixingNav,
} from "@/request/storehouse";
import { GetTupu } from "@/request/tupu";
import { BookMuluText } from "@/utils/base";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends mixins(quanxianMixin) {
  private cTab: any = "通用";
  private clickType: any = "";
  private search: any = "";
  private type: any = "";
  private leftNavs: any = [];
  private selected: any = false; // 左边树是否被选中
  private noClick: any = false; // 导航是否不可点击
  private currentNodeKey: any = "";
  private data: any = {};
  private detail: any = {};
  private fontSize: any = 16;
  private id: any = "";
  private currentZhangjie: any = {};
  private fenleiData: any = []; // 一级分类选项
  private options: any = []; // 二级分类选项
  private ifCompose: any = false;
  private tupuData: any = {};
  private showTupu: any = false;
  private breadList: any = [];
  private isOpenJianjie: any = false;
  private gujiType: any = "list";
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private props: any = {
    label: "名称",
  };
  private get currentKu() {
    return this.$store.state.currentKu;
  }
  private get kuList() {
    return this.$store.state.kuList;
  }
  private goMulu() {
    this.gujiType = "list";
  }
  private goBefore() {
    if (this.currentZhangjie.index === 0) {
      this.$message.warning("已经到底啦！");
      return;
    }
    const index = this.currentZhangjie.index - 1;
    this.id = this.detail.titles[index]._id;
    this.currentZhangjie = this.detail.titles[index];
    this.currentZhangjie.index = index;
    this.getGujiDetail();
  }
  private goNext() {
    if (this.currentZhangjie.index === this.detail.titles.length - 1) {
      this.$message.warning("已经到底啦！");
      return;
    }
    const index = this.currentZhangjie.index + 1;
    this.id = this.detail.titles[index]._id;
    this.currentZhangjie = this.detail.titles[index];
    this.currentZhangjie.index = index;
    this.getGujiDetail();
  }
  private getGujiDetail() {
    const params: any = {
      params: {
        title_id: this.id,
      },
    };
    PostBookContent(this, params).then((res: any) => {
      this.gujiType = "detail";
      this.detail = res;
      if (!this.currentZhangjie || !this.currentZhangjie._id) {
        this.data.titles.forEach((ele: any, index: any) => {
          if (this.id == ele._id) {
            this.currentZhangjie = ele;
            this.currentZhangjie.index = index;
          }
        });
      }
    });
  }
  private goRead(item: any) {
    this.id = item._id;
    this.getGujiDetail();
    // this.$router.push({
    //   path: "/main/storehouse/guji-read",
    //   query: {
    //     id: item._id,
    //     title: this.data["书名"].split("《")[1].split("》")[0],
    //   },
    // });
  }
  private goSearch() {
    if (!this.search) {
      this.$message.warning("请输入关键字！");
      return;
    }
    const d: any = {
      searchText: this.search,
      source: "实体库",
    };
    this.$store.commit("updateCurrentKu", this.type);
    localStorage.setItem("searchFilter", "");
    this.$router.push({
      path: "/main/storehouse/search",
      query: {
        data: JSON.stringify(d),
      },
    });
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private handleNodeClick(data: any, node: any) {
    this.clickType = node.data["类型"];
    if (node.data && node.data["类型"] == "实体") {
      const params: any = {
        params: {
          id: node.data._id,
          need_attr_split: true,
        },
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      GetShitiList(this, params, loading)
        .then((msg: any) => {
          loading.close();
          this.data = msg;
          // this.chuliData();
          const d: any = {
            entity_id: msg.id,
            entity_name: msg["实体名称"],
            entity_type: msg["语义类型"],
          };
          this.drawTupu(d);
        })
        .catch(() => {
          loading.close();
        });
    } else {
      this.data = {};
    }
  }
  private drawTupu(d: any) {
    this.showTupu = false;
    const params: any = {
      params: {
        entity_id: d.entity_id,
        entity_name: d.entity_name,
        entity_type: d.entity_type,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetTupu(this, params).then((data: any) => {
      loading.close();
      this.tupuData = data;
      this.showTupu = true;
      data.nodes.forEach((ele: any, index: any) => {
        if (ele.type === "目标") {
          ele.symbol = "roundRect";
          ele.symbolSize = 25;
        } else if (ele.type === "实体") {
          ele.symbolSize = 20;
        } else {
          ele.symbolSize = 1;
        }
      });
      const Chart: any = echarts.init(this.$refs.Echarts1 as HTMLCanvasElement);
      const options: any = {
        color: this.colors,
        title: {
          text: "",
          subtext: "",
          top: "bottom",
          left: "right",
        },
        tooltip: {},
        legend: [
          {
            // selectedMode: 'single',
            data: data.categories.map(function (a: any) {
              return a.name;
            }),
          },
        ],
        animationDuration: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            name: "",
            type: "graph",
            layout: "force",
            data: data.nodes,
            links: data.links,
            categories: data.categories,
            zoom: 3,
            roam: true,
            label: {
              show: true,
              position: "inside",
              formatter: "{b}",
              color: "#333",
            },
            edgeLabel: {
              show: true,
              formatter: (res: any) => {
                return res.data.name;
              },
            },
            edgeSymbol: ["", "arrow"],
            lineStyle: {
              color: "#333",
              width: 1,
              curveness: 0.1,
            },
            emphasis: {
              show: false,
              focus: "adjacency",
              lineStyle: {
                width: 6,
              },
            },
          },
        ],
      };
      Chart.setOption(options, true);
      // 点击事件
      Chart.off("click");
      Chart.on("click", (p: any) => {
        const data: any = {
          entity_id: p.id,
          entity_name: p["name"],
          entity_type: p["category"],
        };
        // this.drawTupu(data);
      });
    });
  }
  private getWidth() {
    const ele: any = document.getElementById("scrollBox");
    return ele.clientWidth + "px";
  }
  private changePart(index: any) {
    const parant: any = document.getElementById("scrollBox");
    const children: any = document.getElementById("scrollBox" + index);
    if (parant) {
      if (children) {
        const parentTop = parant.offsetTop;
        const childTop = children.offsetTop;
        parant.scrollTop = childTop - parentTop - 14;
      }
    }
  }
  // 递归函数
  private platform(node: any) {
    if (!node.parent) {
      // 若无父节点，则直接返回
      return;
    }
    this.breadList.unshift(node.data); // 将value保存起来
    //调用递归
    this.platform(node.parent);
  }
  private loadNode(node: any, resolve: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        语义类型id: "",
        实体id: "",
      },
    };
    if (node.data && node.data._id) {
      if (node.data["类型"] == "语义类型") {
        params.params["语义类型id"] = node.data._id;
      } else if (node.data["类型"] == "实体") {
        params.params["实体id"] = node.data._id;
      }
    }
    let data: any = [];
    GetShitiNav(this, params)
      .then((res: any) => {
        loading.close();
        if (res.length > 0) {
          data = res;
        }
        const params2: any = {
          params: {
            _id: node.data ? node.data._id : "",
          },
        };
        const loading2 = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        GetYuyileixingNav(this, params2, loading2)
          .then((d: any) => {
            loading2.close();
            if (d.length > 0) {
              if (data.length > 0) {
                data = d.concat(data);
              } else {
                data = d;
              }
            }
            resolve(data);
          })
          .catch(() => {
            loading2.close();
          });
      })
      .catch(() => {
        loading.close();
      });
  }
}
