




















































































































import { Component, Vue } from "vue-property-decorator";
import { Form as elForm } from "element-ui";
import { IsShowQuanxian } from "@/utils/base";
import { clearFilter } from "@/utils/base";
import { PostLogin } from "@/request/account";
import { UserDetail } from "@/request/manage";
import { GetKuList } from "@/request/common";
import Remember from "../../components/remember.vue";
@Component({
  components: {
    Remember,
  },
})
export default class Login extends Vue {
  private type: any = "text";
  private ifRember: any = true;
  private visible: any = false;
  private window: any = window;
  private info: any = {
    用户名: "",
    密码: "",
  };
  private paws: any = [];
  private rules: any = {
    用户名: [{ required: true, message: "用户名必须填写" }],
    密码: [{ required: true, message: "密码必须填写" }],
  };
  private get configuration() {
    return this.$store.state.configuration;
  }
  private pawInput(e: any) {
    if (e) {
      this.type = "password";
    } else {
      this.type = "text";
    }
  }
  private focus() {
    if (localStorage.getItem("paws")) {
      this.paws = JSON.parse(localStorage.getItem("paws") || "");
    }
  }
  private update(e: any) {
    this.type = "password";
    this.info["用户名"] = e.name;
    this.info["密码"] = e.paw;
    // this.paws = [];
  }
  private delPaw(e: any) {
    if (e.length == 0) {
      this.type = "text";
    }
    this.paws = JSON.parse(JSON.stringify(e));
  }
  /**
   * @description 登录
   */
  private login(): void {
    (this.$refs.loginForm as elForm).validate((isValid: boolean) => {
      const Base64 = require("js-base64").Base64;
      if (isValid) {
        const params: any = {
          account: this.info["用户名"],
          密码: Base64.encode(this.info["密码"]),
          login_platform: "研发端",
        };
        PostLogin(this, params).then((res) => {
          const token = res.token;
          const user = res.user;
          this.$store.commit("updateToken", token);
          this.$store.commit("updateUser", user);
          if (this.ifRember) {
            // 如果没有存过则储存，如果已经储存过则更新
            if (this.paws.length === 0) {
              this.paws.push({
                name: this.info["用户名"],
                paw: this.info["密码"],
              });
            } else {
              let ifRepeat: any = false;
              let i: any = "";
              this.paws.forEach((ele: any, index: any) => {
                if (ele.name == this.info["用户名"]) {
                  i = index;
                  ifRepeat = true;
                }
              });
              if (ifRepeat) {
                this.paws.splice(i, 1);
              }
              this.paws.push({
                name: this.info["用户名"],
                paw: this.info["密码"],
              });
            }
          }
          localStorage.setItem("paws", JSON.stringify(this.paws));
          this.goPage();
        });
      }
    });
  }
  private goPage() {
    clearFilter();
    const params: any = {
      params: {
        user_id: this.$store.state.user.user_id,
      },
    };
    UserDetail(this, params).then((res: any) => {
      this.$store.commit("updateQuanxianData", res);
      // 获取知识库列表
      GetKuList(this).then((d: any) => {
        this.$store.commit("updateKuList", d);
        // 跳转默认页面
        if (IsShowQuanxian("首页搜索", res)) {
          this.$router.push("/main/home/show");
        } else if (IsShowQuanxian("实体库", res)) {
          this.$router.push("/main/storehouse/folder-detail");
        } else if (IsShowQuanxian("古籍", res)) {
          const val: any = { label: "古籍", source: "原文库" };
          val.table_name = val.label;
          this.$store.commit("updateCurrentKu", val.table_name);
          const source: any = val.source || "中医药知识库";
          this.$router.push(
            "/main/storehouse/folder?source=" +
              source +
              "&&t=" +
              new Date().getTime()
          );
        } else {
          // for (var i = 0; i < d.length - 1; i++) {
          //   if (IsShowQuanxian(d[i].table_name + "库搜索", res)) {
          //     this.$store.commit("updateCurrentKu", d[i]);
          //     this.$router.push("/main/storehouse/folder");
          //     return;
          //   }
          // }
          if (IsShowQuanxian("实体校验", res)) {
            this.$router.push("/main/check/bentijiaoyan/list");
          } else if (IsShowQuanxian("原文校验", res)) {
            this.$router.push("/main/check/yuanwenjiaoyan/list");
          } else if (IsShowQuanxian("校验管理", res)) {
            this.$router.push("/main/check/jiaoyanguanli/list");
          } else if (IsShowQuanxian("属性来源", res)) {
            this.$router.push("/main/check/shuxinglaiyuan/list");
          } else if (IsShowQuanxian("任务管理", res)) {
            this.$router.push("/main/mark/guanli/list");
          } else if (IsShowQuanxian("任务标注", res)) {
            this.$router.push("/main/mark/biaozhu/list");
          } else if (IsShowQuanxian("任务审核", res)) {
            this.$router.push("/main/mark/shenhe/list");
          } else if (IsShowQuanxian("标注结果库", res)) {
            this.$router.push("/main/mark/jieguo/list");
          } else if (IsShowQuanxian("进度统计", res)) {
            this.$router.push("/main/mark/tongji/folder");
          } else if (IsShowQuanxian("用户管理", res)) {
            this.$router.push("/main/manage/zhanghu/list");
          } else if (IsShowQuanxian("角色管理", res)) {
            this.$router.push("/main/manage/juese/list");
          }
        }
      });
    });
  }
  private mounted() {
    // const token = localStorage.getItem("token");
    // const user: any = JSON.parse(localStorage.getItem("user") || "");
    // if (token) {
    //   this.$store.commit("updateToken", token);
    //   if (!this.$store.state.user.user_id) {
    //     this.$store.commit("updateUser", user);
    //   }
    //   this.goPage();
    // }
    if (localStorage.getItem("paws")) {
      this.paws = JSON.parse(localStorage.getItem("paws") || "");
    }
    console.log("login");
  }
}
