






























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private tupuData: any;
  @Watch("tupuData", { immediate: true })
  private tupuDataChange() {
    this.graph = JSON.parse(JSON.stringify(this.tupuData));
    this.graph.nodes.forEach((node: any, i: any) => {
      if (node.type === "目标") {
        node.symbol = "roundRect";
        node.symbolSize = 25;
      } else if (node.type === "实体") {
        node.symbolSize = 20;
      } else {
        node.symbolSize = 1;
      }
    });
  }
  private ifShowTupu: any = false;
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private graph: any = {
    categories: [],
    links: [],
    nodes: [],
  };
  private option: any = {
    color: this.colors,
    title: {
      text: "",
      subtext: "",
      top: "bottom",
      left: "right",
    },
    tooltip: {},
    legend: [
      {
        top: 10,
        left: 10,
        orient: "vertical",
        // selectedMode: 'single',
        // data: this.graph.categories.map(function (a: any) {
        //   return a.name;
        // }),
        data: [],
      },
    ],
    animationDuration: 1000,
    animationEasingUpdate: "quinticInOut",
    series: [
      {
        name: "",
        type: "graph",
        layout: "force",
        data: this.graph.nodes,
        links: this.graph.links,
        categories: this.graph.categories,
        zoom: 3,
        roam: true,
        draggable: true,
        label: {
          show: true,
          position: "inside",
          formatter: "{b}",
          color: "#333",
        },
        edgeLabel: {
          show: true,
          formatter: (res: any) => {
            return res.data.name;
          },
        },
        edgeSymbol: ["", "arrow"],
        lineStyle: {
          color: "#333",
          width: 1,
          curveness: 0.1,
        },
        emphasis: {
          show: false,
          focus: "adjacency",
          lineStyle: {
            width: 6,
          },
        },
      },
    ],
  };
  private exit() {
    this.ifShowTupu = false;
  }
  private openBigTupu() {
    this.ifShowTupu = true;
    this.redrawGraphBig();
  }

  // 根据更新后的option重新画图
  private redrawGraphBig() {
    let option: any = {
      color: this.colors,
      title: {
        text: "",
        subtext: "",
        top: "bottom",
        left: "right",
      },
      tooltip: {},
      legend: [
        {
          top: 10,
          left: 10,
          orient: "vertical",
          // selectedMode: 'single',
          data: this.graph.categories.map(function (a: any) {
            return a.name;
          }),
        },
      ],
      animationDuration: 1000,
      animationEasingUpdate: "quinticInOut",
      series: [
        {
          name: "",
          type: "graph",
          layout: "force",
          data: this.graph.nodes,
          links: this.graph.links,
          categories: this.graph.categories,
          zoom: 3,
          roam: true,
          draggable: true,
          symbolSize: 1,
          itemStyle: {
            // color: "transparent",
            // borderColor: "#333",
            // borderType: "solid",
          },
          label: {
            show: true,
            position: "inside",
            formatter: "{b}",
            color: "#333",
          },
          edgeLabel: {
            show: true,
            formatter: (res: any) => {
              return res.data.name;
            },
          },
          edgeSymbol: ["", "arrow"],
          lineStyle: {
            color: "#333",
            width: 1,
            curveness: 0.1,
          },
          emphasis: {
            show: false,
            focus: "adjacency",
            lineStyle: {
              width: 6,
            },
          },
        },
      ],
    };
    const Chart: any = echarts.init(this.$refs.tupuBig as HTMLCanvasElement);
    Chart.setOption(option, true);
    // 点击事件
    Chart.off("click");
    Chart.on("click", (p: any) => {});
  }

  private mounted() {}
}
