






























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import echarts from "echarts";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    // this.tupuData = JSON.parse(JSON.stringify());
    this.tupuData = {
      categories: JSON.parse(JSON.stringify(this.data.categories)),
      links: JSON.parse(JSON.stringify(this.data.links)),
      nodes: JSON.parse(JSON.stringify(this.data.nodes)),
    };
    this.refreshTree();
    this.drawTupu1();
  }
  private clickTreeData: any = [];
  private ifShowTupu: any = false;
  private tupuData: any = {
    categories: [],
    links: [],
    nodes: [],
  };
  private stagingData: any = {};
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  // 初始化树结构
  private refreshTree() {
    this.clickTreeData = this.data.nodes;
  }
  private openBigTupu() {
    this.ifShowTupu = true;
    this.drawTupu2();
  }
  private drawTupu1() {
    console.log(999);
    console.log(this.tupuData);
    const Chart: any = echarts.init(this.$refs.tupu as HTMLCanvasElement);
    const options: any = {
      color: this.colors,
      title: {
        text: "",
        subtext: "",
        top: "bottom",
        left: "right",
      },
      tooltip: {},
      grid: {
        left: "30%",
      },
      legend: {
        top: 10,
        left: 10,
        orient: "vertical",
        // selectedMode: 'single',
        data: this.tupuData.categories.map(function (a: any) {
          return a.name;
        }),
        formatter: (params: any) => {
          let num: any = 0;
          this.tupuData.nodes.forEach((ele: any) => {
            if (ele.category == params) {
              num += 1;
            }
          });
          return params + "(" + num + ")";
        },
      },
      series: [
        {
          name: "",
          type: "graph",
          // layout: "none",
          layout: "force",
          // layout: "circular",
          data: this.tupuData.nodes,
          links: this.tupuData.links,
          categories: this.tupuData.categories,
          zoom: 3,
          roam: true,
          label: {
            show: true,
            position: "inside",
            formatter: "{b}",
            color: "#333",
          },
          edgeLabel: {
            show: true,
            formatter: (res: any) => {
              return res.data.name;
            },
          },
          edgeSymbol: ["", "arrow"],
          lineStyle: {
            color: "#333",
            width: 1,
            curveness: 0.1,
          },
          emphasis: {
            show: false,
            focus: "adjacency",
            lineStyle: {
              width: 6,
            },
          },
          force: {
            layoutAnimation: true, //初始化转动动画,这里如果关闭会导致关系线错位
          },
          draggable: true, // 是否可拖动
        },
      ],
    };
    Chart.setOption(options, true);
    // 点击事件
    // 点击事件
    Chart.off("click");
    Chart.on("click", (p: any) => {
      this.chartClick(p.data);
    });
  }
  private drawTupu2() {
    const Chart: any = echarts.init(this.$refs.tupu2 as HTMLCanvasElement);
    const options: any = {
      color: this.colors,
      title: {
        text: "",
        subtext: "",
        top: "bottom",
        left: "right",
      },
      tooltip: {},
      legend: {
        top: 10,
        left: 10,
        orient: "vertical",
        // selectedMode: 'single',
        data: this.tupuData.categories.map(function (a: any) {
          return a.name;
        }),
        formatter: (params: any) => {
          let num: any = 0;
          this.tupuData.nodes.forEach((ele: any) => {
            if (ele.category == params) {
              num += 1;
            }
          });
          return params + "(" + num + ")";
        },
      },
      animationDuration: 1500,
      animationEasingUpdate: "quinticInOut",
      series: [
        {
          name: "",
          type: "graph",
          layout: "force",
          data: this.tupuData.nodes,
          links: this.tupuData.links,
          categories: this.tupuData.categories,
          zoom: 3,
          roam: true,
          label: {
            show: true,
            position: "inside",
            formatter: "{b}",
            color: "#333",
          },
          edgeLabel: {
            show: true,
            formatter: (res: any) => {
              return res.data.name;
            },
          },
          edgeSymbol: ["", "arrow"],
          lineStyle: {
            color: "#333",
            width: 1,
            curveness: 0.1,
          },
          emphasis: {
            show: false,
            focus: "adjacency",
            lineStyle: {
              width: 6,
            },
          },
          force: {
            layoutAnimation: true, //初始化转动动画,这里如果关闭会导致关系线错位
          },
          draggable: true, // 是否可拖动
        },
      ],
    };
    Chart.setOption(options, true);
    // 点击事件
    // 点击事件
    Chart.off("click");
    Chart.on("click", (p: any) => {
      this.chartClick(p.data);
    });
  }
  // 点击图谱事件
  private chartClick(node: any) {
    // 如果点击需要展开或者收起相关数据
    if (this.data.data[node.id].open) {
      // 收起相关数据
      this.data.data[node.id].open = false;
      if (node.category == "中医疾病") {
        this.refresh();
        return;
      }
      // 清除点击结构数据里面当前点击node下面的children数据
      this.clearNode(this.clickTreeData, node);
      // 初始化暂存数据
      this.stagingData = {
        categories: this.data.categories,
        links: this.data.links,
        nodes: this.data.nodes,
      };
      // 按照树再展开一次
      this.autoClick(this.clickTreeData);
      // 去重渲染
      this.deduplication(this.stagingData);
    } else {
      // 展开点击证型相关数据
      // 如果下级没有直接返回不要处理
      if (!this.data.data[node.id]) {
        return;
      }
      // 记录是否展开
      this.data.data[node.id].open = true;
      // 拼接点击路径，拿到当前父节点，并且放入点击的树路径之下
      this.findNode(this.clickTreeData, node);
      // 拼接点击节点的数据
      const currentData = {
        categories: this.tupuData.categories.concat(
          this.data.data[node.id].categories
        ),
        links: this.tupuData.links.concat(this.data.data[node.id].links),
        nodes: this.tupuData.nodes.concat(this.data.data[node.id].nodes),
      };
      const Chart: any = echarts.init(this.$refs.tupu2 as HTMLCanvasElement);
      Chart.clear();
      // 去重渲染
      this.deduplication(currentData);
    }
  }
  // 去重
  private deduplication(data: any) {
    let categories: any = data.categories.filter((item: any, index: any) => {
      return (
        data.categories.findIndex((obj: any) => obj.name === item.name) ===
        index
      );
    });
    let links: any = data.links.filter((item: any, index: any) => {
      return (
        data.links.findIndex(
          (obj: any) =>
            obj.name === item.name &&
            obj.source === item.source &&
            obj.target === item.target
        ) === index
      );
    });
    let nodes: any = data.nodes.filter((item: any, index: any) => {
      return data.nodes.findIndex((obj: any) => obj.id === item.id) === index;
    });
    this.tupuData = {
      categories: categories,
      links: links,
      nodes: nodes,
    };
    if (this.ifShowTupu) {
      this.drawTupu2();
    } else {
      this.drawTupu1();
    }
  }
  private autoClick(arr: any) {
    // 遍历数组中的每个对象
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];

      // 拼接点击数据
      if (this.data.data[currentObj.id]) {
        const currentData = {
          categories: this.stagingData.categories.concat(
            this.data.data[currentObj.id].categories
          ),
          links: this.stagingData.links.concat(
            this.data.data[currentObj.id].links
          ),
          nodes: this.stagingData.nodes.concat(
            this.data.data[currentObj.id].nodes
          ),
        };
        this.stagingData = currentData;
      }

      // 如果当前对象有children，递归地处理children
      if (Array.isArray(currentObj.children)) {
        this.autoClick(currentObj.children);
      }
    }
  }
  private findNode(arr: any, newObj: any) {
    // 遍历数组中的每个对象
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];

      // 检查当前对象的 id 是否与传入对象的 parent_id 匹配
      if (currentObj.id === newObj.parent_id) {
        if (!currentObj.children) {
          currentObj.children = [];
        }
        // 如果匹配，将新对象添加到当前对象的 children 数组中
        currentObj.children.push(newObj);
        return; // 找到后可以直接返回，因为每个对象的 id 应该是唯一的
      }

      // 如果当前对象有 children，递归地在其 children 中查找
      if (
        Array.isArray(currentObj.children) &&
        currentObj.children.length > 0
      ) {
        this.findNode(currentObj.children, newObj);
      }
    }

    // 如果没有找到匹配的 parent_id，函数将静默失败（不执行任何操作）
  }
  private clearNode(arr: any, targetObj: any) {
    // 如果点击的是最外面一层就直接刷新，默认最外层疾病只有一个
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];

      // 检查当前对象的 id 是否与传入的 targetParentId 匹配
      // 检查当前对象的 id 是否与传入的 targetObj.id 匹配
      if (currentObj.id === targetObj.parent_id) {
        // 如果匹配，删除其 children 属性
        // 需要改变children里面的open为false
        if (currentObj.children && currentObj.children.length > 0) {
          this.autoHide(JSON.parse(JSON.stringify(currentObj.children)));
        }
        currentObj.children = [];
      } else {
        // 如果当前对象有 children，递归地在其 children 中查找
        if (
          Array.isArray(currentObj.children) &&
          currentObj.children.length > 0
        ) {
          this.clearNode(currentObj.children, targetObj);
        }
      }
    }

    return false; // 如果遍历完整个数组都没有找到，则返回false
  }
  // 隐藏节点时修改open字段
  private autoHide(arr: any) {
    for (let i = 0; i < arr.length; i++) {
      const currentObj = arr[i];
      if (this.data.data[currentObj.id]) {
        this.data.data[currentObj.id].open = false;
      }

      // 如果当前对象有 children，递归地处理 children
      if (Array.isArray(currentObj.children)) {
        this.autoHide(currentObj.children);
      }
    }
  }
  private exit() {
    this.ifShowTupu = false;
  }
  // 刷新图谱
  private refresh() {
    this.tupuData = {
      categories: this.data.categories,
      links: this.data.links,
      nodes: this.data.nodes,
    };
    this.drawTupu1();
    this.drawTupu2();
    // 需要把所有的展开状态初始化
    for (const key in this.data.data) {
      if (Object.prototype.hasOwnProperty.call(this.data.data, key)) {
        const element = this.data.data[key];
        element.open = false;
      }
    }
    // 初始化点击树
    this.refreshTree();
  }
}
