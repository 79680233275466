














































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { clearFilter } from "@/utils/base";
import { EditPwd } from "@/request/manage";
import { GetKuList } from "@/request/common";
import { GetBiaozhuOption } from "@/request/mark";
import { getOption, configuration } from "@/setting";
import { GetZhuantis } from "@/request/schema";
@Component({})
export default class Name extends mixins(quanxianMixin) {
  private window: any = window;
  private ifShowPassword: any = false;
  private list: any = [];
  private newPassword: string = "";
  private renewPassword: string = "";
  private oldPassword: string = "";
  private navs: any = {
    知识校验: [
      {
        name: "原文管理",
        label: "原文管理",
        path: "/main/check/ywgl-list",
      },
      {
        name: "原文校验",
        label: "原文校验",
        path: "/main/check/yuanwenjiaoyan/list",
      },
      {
        name: "原文审核",
        label: "原文审核",
        path: "/main/check/ywsh-list",
      },
      {
        name: "原文切分",
        label: "原文切分",
        path: "/main/check/ywqf-list",
      },
      {
        name: "实体管理",
        label: "实体管理",
        path: "/main/check/stgl-list",
      },
      {
        name: "实体校验",
        label: "实体校验",
        path: "/main/check/bentijiaoyan/list",
      },
      // {
      //   name: "校验管理",
      //   label: "校验管理",
      //   path: "/main/check/jiaoyanguanli/list",
      // },
      // {
      //   name: "属性来源",
      //   label: "属性来源",
      //   path: "/main/check/shuxinglaiyuan/list",
      // },
      {
        name: "schema管理",
        label: "Schema管理",
        path: "/main/mark/schema/folder",
      },
    ],
    知识标注: [
      {
        name: "任务管理",
        label: "任务管理",
        path: "/main/mark/guanli/list",
      },
      {
        name: "任务标注",
        label: "任务标注",
        path: "/main/mark/biaozhu/list",
      },
      {
        name: "任务审核",
        label: "任务审核",
        path: "/main/mark/shenhe/list",
      },
      {
        name: "标注结果库",
        label: "标注结果库",
        path: "/main/mark/jieguo/list",
      },
      {
        name: "进度统计",
        label: "进度统计",
        path: "/main/mark/tongji/folder",
      },
    ],
    系统管理: [
      {
        name: "用户管理",
        label: "账户管理",
        path: "/main/manage/zhanghu/list",
      },
      {
        name: "角色管理",
        label: "角色管理",
        path: "/main/manage/juese/list",
      },
      {
        name: "申请管理",
        label: "申请管理",
        path: "/main/manage/apply/list",
      },
      {
        name: "消息管理",
        label: "消息管理",
        path: "/main/manage/info/list",
      },
      {
        name: "资料管理",
        label: "资料管理",
        path: "/main/manage/material/list",
      },
    ],
    //专题库: [],
  };
  private zhauntis: any = [];
  private get user() {
    return this.$store.state.user;
  }
  private get Allku() {
    return this.$store.state.kuList;
  }
  private get kuList() {
    const num: any = 10;
    let data: any = [];
    if (this.$store.state.kuList.length > num) {
      this.$store.state.kuList.forEach((items: any, index: any) => {
        if ((index + 1) % num === 1) {
          data.push([items]);
        } else {
          data[data.length - 1].push(items);
        }
      });
    } else {
      data = [this.$store.state.kuList];
    }
    return data;
  }
  private getShowZhuantiku() {
    let ifShow: any = false;
    this.navs["专题库"].forEach((item: any) => {
      if (this.getIsShowQuanxian(item.name)) {
        ifShow = true;
      }
    });
    return ifShow;
  }
  private getPartQuanxian(val: any) {
    let result: any = false;
    if (val == "中医药知识库") {
      this.Allku.forEach((ele: any) => {
        if (this.getIsShowQuanxian(ele.table_name + "库搜索")) {
          result = true;
        }
      });
    } else if (val == "实体库") {
    } else {
      this.navs[val].forEach((ele: any) => {
        if (this.getIsShowQuanxian(ele.name)) {
          result = true;
        }
      });
    }
    return result;
  }
  private goDefault() {
    // clearFilter();
    // this.$router.push("/main/home/show");
  }
  private goZhishiku(val: any) {
    clearFilter();
    val.table_name = val.label;
    this.$store.commit("updateCurrentKu", val.table_name);
    const source: any = val.source || "中医药知识库";
    this.$router.push(
      "/main/storehouse/folder?source=" + source + "&&t=" + new Date().getTime()
    );
  }
  private goRouter(item: any) {
    clearFilter();
    this.$router.push(item.path);
    this.$store.commit("updateJibing", {});
    if (item.parent == "专题库") {
      this.$store.commit("updateZhuanti", item.label);
    }
    if (
      item.parent == "专题库" &&
      window.location.href.indexOf("/main/zsyy/yian/yian-study") !== -1
    ) {
      setTimeout(() => {
        location.reload();
      }, 500);
    }
  }
  /**
   * @description 切换知识库
   */
  private changeModel(val: any) {
    this.$store.commit("updateModel", val);
    location.reload();
  }
  /**
   * @description 点击切换账号
   */
  private handleCommand(command: any) {
    if (command === "修改密码") {
      this.oldPassword = "";
      this.newPassword = "";
      this.renewPassword = "";
      this.ifShowPassword = true;
    } else if (command === "退出登录") {
      localStorage.removeItem("token");
      this.$router.replace("/access/login");
    }
  }
  /**
   * @description 跳转个人中心
   */
  private goAccountManage() {
    localStorage.setItem(
      "beforeAccountSource",
      JSON.stringify({
        path: this.$route.path,
        query: this.$route.query,
      })
    );
    this.$router.push({
      path: "/main/account/safe/safe-folder",
    });
  }
  /**
   * @description 确定修改密码
   */
  private edit() {
    const reg = /^(?=.*[0-9])(?=.*[a-zA-Z])(.{8,})$/;
    if (!this.oldPassword) {
      this.$message.warning("当前密码不能为空");
      return;
    }
    if (!this.newPassword) {
      this.$message.warning("新密码不能为空");
      return;
    }
    if (!this.renewPassword) {
      this.$message.warning("重复新密码不能为空");
      return;
    }
    if (!reg.test(this.oldPassword)) {
      this.$message.warning(
        "当前密码长度必须8位及以上，且需同时包含字母和数字"
      );
      return;
    }
    if (!reg.test(this.newPassword)) {
      this.$message.warning("新密码长度必须8位及以上，且需同时包含字母和数字");
      return;
    }
    if (this.newPassword !== this.renewPassword) {
      this.$message.error("两次输入的密码不一致，请重新输入");
      // this.newPassword = "";
      // this.renewPassword = "";
      return;
    }
    const params = {
      user_id: this.user.user_id,
      old_password: this.oldPassword,
      new_password: this.newPassword,
    };
    EditPwd(this, params).then(() => {
      this.$message.success("修改成功");
      this.newPassword = "";
      this.renewPassword = "";
      this.ifShowPassword = false;
      localStorage.removeItem("token");
      this.$router.push("/access/login");
    });
  }
  private getOption() {
    const params: any = {
      kind: "语义类型",
    };
    GetBiaozhuOption(this, params).then((res: any) => {
      this.list = res;
    });
  }
  private getZhuanti() {
    GetZhuantis(this).then((res: any) => {
      this.zhauntis = res;
      let arr: any = [];
      res.forEach((ele: any) => {
        const obj = {
          name: "专题库" + ele,
          label: ele,
          parent: "专题库",
          path: "/main/zsyy/yian/folder?type=" + ele,
        };
        if (ele !== "医案病历") {
          obj.path = "/main/zsyy/yian/yian-study?type=" + ele;
        }
        arr.push(obj);
      });
      this.navs["专题库"] = arr;
    });
  }
  mounted() {
    // // 自动登录
    // const user: any = JSON.parse(localStorage.getItem("user") || "");
    // if (!this.$store.state.user.user_id) {
    //   this.$store.commit("updateUser", user);
    // }
    // // 获取知识库列表
    // GetKuList(this).then((res: any) => {
    //   this.$store.commit("updateKuList", res);
    // });
    // // this.getOption();
    // this.getZhuanti();
  }
}
