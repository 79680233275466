












































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import {
  PatchInfo,
  PostInfo,
  AddInfo,
  DeleteInfo,
  GetInstitution,
  GetUserListAll,
} from "@/request/common";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private data: any[] = [];
  private ifShowEdit: any = false;
  private editData: any = {
    target_type: "全部",
    target_items: [],
  };
  private jigous: any = [];
  private accounts: any = [];
  private changeType(e: any) {
    this.editData.target_items = [];
  }
  private openEdit(item?: any) {
    if (item) {
      this.editData = JSON.parse(JSON.stringify(item));
    } else {
      this.editData = {};
    }
    this.ifShowEdit = true;
  }
  private fabu(item: any, val: any) {
    const params: any = {
      状态: val,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PatchInfo(this, item.id, params, loading).then((res: any) => {
      this.$message.success((val == "未发布" ? "撤回" : "发布") + "成功");
      this.ifShowEdit = false;
      this.search();
    });
  }
  private editSure() {
    if (!this.editData["内容"]) {
      this.$message.warning("请输入消息内容");
      return;
    }
    if (this.editData.target_type !== "全部") {
      if (this.editData.target_items == 0) {
        this.$message.warning("请选择需要发布的机构或个人");
        return;
      }
    }
    if (this.editData.id) {
      const params: any = {
        内容: this.editData["内容"],
        target_type: this.editData.target_type,
        target_items: this.editData.target_items,
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      PatchInfo(this, this.editData.id, params, loading).then((res: any) => {
        this.$message.success("保存成功");
        this.ifShowEdit = false;
        this.search();
      });
    } else {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      AddInfo(this, this.editData, loading).then((res: any) => {
        this.$message.success("保存成功");
        this.ifShowEdit = false;
        this.search();
      });
    }
  }
  private deleteOne(item: any) {
    this.$confirm("确定删除该记录么？", "删除", {
      customClass: "commonConfirm",
    }).then((res: any) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      DeleteInfo(this, item.id, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("删除成功");
          this.search();
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      状态: this.filters["状态"],
      search: this.filters.search,
      order_field: "更新时间",
      order_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
    };
    localStorage.setItem("shenqingFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostInfo(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private getJigous() {
    GetInstitution(this, {}).then((res: any) => {
      this.jigous = res;
    });
  }
  private getAccount() {
    const params: any = {
      params: {
        access_control: ["用户端"],
      },
    };
    GetUserListAll(this, params).then((res: any) => {
      this.accounts = res;
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    const d = localStorage.getItem("shenqingFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
    this.getJigous();
    this.getAccount();
  }
}
