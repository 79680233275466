





































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { drawBg, drawText, getStringWidth, splitData } from "@/utils/biaozhu";
import {
  PostBookContent,
  createRenwu,
  GetBookMulu2,
} from "@/request/storehouse";
import {
  GetBiaozhuyuan,
  GetCategory,
  GetRenwuOption,
  AddRenwu,
} from "@/request/mark";
import { GetZhuantiBook, PostZhuantiBookContent } from "@/request/common";
@Component({})
export default class Name extends Vue {
  @Prop()
  private type: any;
  @Prop()
  private filter: any;
  @Prop()
  private ifEdit: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.renwuData = this.data.records;
    this.createContent();
  }
  private parentData: any = {
    章节: {},
    书名: {},
  };
  private W: any = 0; // 内容区域宽度
  private contentHeight: any = 100;
  private allString: any = ""; // 所有原文内容字符串
  private strArrData: any = []; // 字符串数组，一个字符为一项
  private defaultLeft: any = 20; // 默认的左间距，给标记留点溢出空间
  private defaultRight: any = 30; // 默认的右间距，给标记留点溢出空间。页面有滚动条时会占据掉15左右，所以右边会比左边多给点
  private lineicon: any = "\n"; // 换行标识符
  private lineData: any = []; // 行上方间距，根据标记的内容多少自动计算
  private fontSize: any = 16; // 文字大小
  private defaultTop: any = 20; // 默认的行间距
  private oneLineBiaozhuH: any = 40; // 每行标注高度
  private startPoint: any = {}; // 鼠标按下的坐标
  private endPoint: any = {}; // 鼠标抬起的坐标
  private drawing: any = false; // 是否正在绘制中,为了方便鼠标离开绘制区域时能够及时结束绘制
  private currentSelectText: any = "";
  private dialogData: any = {};
  private renwuData: any = [];
  private ifShowBiaozhuDialog: any = false;
  private ifShowShenheDialog: any = false;
  private fenleis: any = [];
  private biaozhuyuans: any = [];
  private renwus: any = [];
  private numT: any = 0;
  private numL: any = 0;
  private num: any = 0;
  private showNum: any = false;
  private searchData: any = ""; //高级检索的检索内容
  private books: any = [];
  private zhangjies: any = [];
  private force() {
    this.$forceUpdate();
  }
  private addShenhe() {
    console.log(this.dialogData);
    if (!this.dialogData.text) {
      this.$message.warning("请选择正文内容");
      return;
    }
    if (!this.dialogData.book_id && !this.dialogData["书名"]) {
      this.$message.warning("请选择书名");
      return;
    }
    if (!this.dialogData.zhangjie_id && !this.dialogData["章节名"]) {
      this.$message.warning("请选择章节名");
      return;
    }
    const params: any = {
      正文: this.dialogData.text,
      book_id: this.dialogData.book_id,
      书名: this.dialogData["书名"],
      book_content_id: this.dialogData.zhangjie_id,
      章节名: this.dialogData["章节名"],
    };
    console.log(params);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostZhuantiBookContent(this, params, loading).then((res: any) => {
      loading.close();
      this.ifShowShenheDialog = false;
    });
  }
  private remoteBookNameMethod(val: any) {
    this.dialogData["书名"] = val;
    this.dialogData.book_id = "";
    const params: any = {
      params: {
        search: val,
      },
    };
    GetZhuantiBook(this, params).then((res: any) => {
      this.books = res;
    });
  }
  private remoteZhangjieNameMethod(val: any) {
    this.dialogData["章节名"] = val;
    this.dialogData.zhangjie_id = "";
    if (!this.dialogData.book_id) {
      this.zhangjies = [];
      return;
    }
    const params: any = {
      params: {
        search: val,
        book_id: this.dialogData.book_id,
      },
    };
    GetBookMulu2(this, params).then((res: any) => {
      this.zhangjies = res;
    });
  }
  private bookNameChange(e: any) {
    this.dialogData["书名"] = e["书名"];
    this.dialogData.book_id = e.book_id;
    this.dialogData["章节名"] = "";
    this.dialogData.zhangjie_id = "";
    this.$forceUpdate();
  }
  private zhangjieNameChange(e: any) {
    this.dialogData["章节名"] = e["章节名"];
    this.dialogData.zhangjie_id = e._id;
    this.$forceUpdate();
  }
  private handleMouseDown(e: any) {
    if (!this.ifEdit) {
      return;
    }
    // 禁掉浏览器的选中文字效果
    document.onselectstart = function () {
      return false;
    };
    // 重置数据
    this.startPoint = {};
    this.endPoint = {};
    // 记录开始绘制行为
    this.drawing = true;
    // 记录绘制起点坐标信息
    const line: any = this.getLineNum(e.offsetY);
    const pointY = this.lineData[line - 1].top - this.fontSize;
    this.startPoint = {
      x: e.offsetX,
      y: pointY,
      line: line,
    };
    this.showNum = true;
    this.numL = e.clientX;
    this.numT = e.clientY;
  }
  private handleMouseMove(e: any) {
    if (!this.ifEdit) {
      return;
    }
    // 如果不是在绘制中不需要触发计算行为
    if (!this.drawing) {
      return;
    }
    // 当前点的坐标信息
    const line: any = this.getLineNum(e.offsetY);
    const pointY = this.lineData[line - 1].top - this.fontSize;
    this.endPoint = {
      x: e.offsetX,
      y: pointY,
      line: line,
    };
    this.numL = e.clientX;
    this.numT = e.clientY;
    this.countNum();
    // 给选中的文本加上背景色
    const dom: any = document.getElementById("select2");
    // 清空绘制路径
    const child: any = dom.childNodes;
    // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
    if (child && child.length > 0) {
      for (var i = child.length - 1; i >= 0; i--) {
        dom.removeChild(child[i]);
      }
    }
    // 根据当前的起终点拿出符合的数据，并给这些数据绘制底色
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    arr.forEach((d: any) => {
      const x: any = d.startX - 2;
      const y: any = this.lineData[d.line - 1].top - this.fontSize + 2;
      const width: any = d.width + 2;
      drawBg(dom, x, y, width, this.fontSize + 2, "", "#409eff");
    });
  }
  private handleMouseUp(e: any) {
    this.showNum = false;
    this.num = 0;
    if (!this.ifEdit) {
      return;
    }
    // 清空绘制路径
    const dom: any = document.getElementById("select2");
    const child: any = dom.childNodes;
    // 如果从前面开始删会导致索引补位，只能删除一半，所以需要从后面开始删除
    if (child && child.length > 0) {
      for (var i = child.length - 1; i >= 0; i--) {
        dom.removeChild(child[i]);
      }
    }
    // 如果不是在绘制中，且没有滑动不需要触发计算行为
    if (!this.drawing || !this.endPoint.x) {
      this.drawing = false;
      return;
    }
    this.drawing = false;
    this.showNum = false;
    this.endDrawing();
  }
  private countNum() {
    // 判断当前数据是否超出标注字数限制
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    if (arr.length == 0) {
      return;
    }
    let text = "";
    // 计算选中区域的长度
    arr.forEach((opt: any) => {
      text += opt.text;
    });
    this.num = text.length;
  }
  private endDrawing() {
    // 判断当前数据是否超出标注字数限制
    const arr: any = this.getSelectArr(this.startPoint, this.endPoint);
    if (arr.length == 0) {
      return;
    }
    this.currentSelectText = "";
    // 计算选中区域的长度
    arr.forEach((opt: any) => {
      this.currentSelectText += opt.text;
    });
    //记录文本标注信息，需要在保存的时候发送给后端保存
    this.dialogData = {
      index: [arr[0].index, arr[arr.length - 1].index], // 标记的开始-结束索引
      text: this.currentSelectText, // 标记的文本
    };
    if (this.type == "标注任务") {
      this.remoteNameMethod("");
      this.ifShowBiaozhuDialog = true;
    } else if (this.type == "审核任务") {
      this.ifShowShenheDialog = true;
    }
    this.showNum = false;
  }
  // 根据始终点循坏原始数据生成符合条件的集合
  private getSelectArr(startPoint: any, endPoint: any) {
    let startLine: any = startPoint.line;
    let endLine: any = endPoint.line;
    let startX: any = startPoint.x;
    let endX: any = endPoint.x;
    let arr: any = [];
    // 区分起终点
    if (startLine == endLine && startX > endX) {
      startX = endPoint.x;
      endX = startPoint.x;
    }
    if (startLine !== endLine && startLine > endLine) {
      startLine = endPoint.line;
      endLine = startPoint.line;
      startX = endPoint.x;
      endX = startPoint.x;
    }
    // 根据调整后的起终点拿取正确的数组内容，需要区分单行和多行
    this.strArrData.forEach((ele: any, index: any) => {
      if (startLine == endLine) {
        if (
          ele.line == startLine &&
          startX < ele.startX + ele.width / 2 &&
          endX > ele.startX + ele.width / 2
        ) {
          arr.push(ele);
        }
      } else {
        // 多行，需要区分是首行（起点~右边），末行（左边~终点）还是中间行（全部）
        if (
          // 首行
          ele.line == startLine &&
          ele.startX + ele.width / 2 > this.startPoint.x
        ) {
          arr.push(ele);
        } else if (
          ele.line == endLine &&
          ele.startX + ele.width / 2 < this.endPoint.x
        ) {
          arr.push(ele);
        } else if (ele.line > startLine && ele.line < endLine) {
          arr.push(ele);
        }
      }
    });
    return arr;
  }
  // 根据y坐标计算所在行
  private getLineNum(y: any) {
    let line: any = 0;
    this.lineData.forEach((item: any, itemIndex: any) => {
      if ((y > item.range[0] || y == item.range[0]) && y < item.range[1]) {
        line = item.line;
      }
    });
    return line;
  }
  // 生成单个字符串的字符串数组
  private getStrArr() {
    this.allString = this.data.content;
    this.strArrData = [];
    const arr = splitData([this.allString], this.lineicon);
    let line: any = 1;
    let x: any = this.defaultLeft;
    this.lineData = [{ line: 1, num: 0, top: 0, range: [], 标注: [] }];
    arr.forEach((e: any, i: any) => {
      if (line !== this.lineData[this.lineData.length - 1].line) {
        this.lineData.push({ line: line, num: 0, top: 0, range: [], 标注: [] });
      }
      // 获取字符串文本长度
      let width = getStringWidth(e, this.fontSize, this.lineicon);
      // 生成基础信息数组strArrData，存取基础信息
      if (e.length === 1) {
        const strWidth: any = getStringWidth(e, this.fontSize, this.lineicon);
        if (this.strArrData.length === 0) {
          const strObj: any = {
            index: 0,
            text: e,
            width: strWidth,
            line: line,
            startX: x, // 开始x轴坐标
          };
          if (e == this.lineicon) {
            strObj.width = 0;
          }
          this.strArrData.push(strObj);
        } else {
          const strLast: any = this.strArrData[this.strArrData.length - 1];
          const strObj: any = {
            index: strLast.index + 1,
            text: e,
            width: strWidth,
            line: line,
            startX: strLast.startX + strLast.width, // 开始x轴坐标
          };
          if (e == this.lineicon) {
            strObj.width = 0;
          }
          if (strLast.line != line) {
            strObj.startX = x;
          }
          this.strArrData.push(strObj);
        }
      } else {
        e.split("").forEach((text: any) => {
          const strWidth: any = getStringWidth(
            text,
            this.fontSize,
            this.lineicon
          );
          if (this.strArrData.length === 0) {
            const strObj: any = {
              index: 0,
              text: text,
              width: strWidth,
              line: line,
              startX: x, // 开始x轴坐标
            };
            if (text == this.lineicon) {
              strObj.width = 0;
            }
            this.strArrData.push(strObj);
          } else {
            const strLast: any = this.strArrData[this.strArrData.length - 1];
            const strObj: any = {
              index: strLast.index + 1,
              text: text,
              width: strWidth,
              line: line,
              startX: strLast.startX + strLast.width, // 开始x轴坐标
            };
            if (text == this.lineicon) {
              strObj.width = 0;
            }
            if (strLast.line != line) {
              strObj.startX = x;
            }
            this.strArrData.push(strObj);
          }
        });
      }
      // 计算下一条数据
      // 非最后一项长度超过屏幕内容宽度或者下一个元素是换行符,需要换行
      // 如果下一个有标注，且长度会超过屏幕或者中间有换行符也需要换行
      const strLast: any = this.strArrData[this.strArrData.length - 1];
      if (
        i !== arr.length - 1 &&
        (x + getStringWidth(arr[i + 1], this.fontSize, this.lineicon) >
          this.W - this.defaultRight ||
          e == this.lineicon)
      ) {
        line += 1;
        x = this.defaultLeft;
      } else {
        x += width;
      }
    });
  }
  // 最终生成行数据,getStrArr会生成line字段，这里是生成剩下的top和range字段
  private getLineDate() {
    this.lineData.forEach((ele: any, index: any) => {
      if (index == 0) {
        const top =
          this.defaultTop +
          this.fontSize +
          ele["标注"].length * this.oneLineBiaozhuH;
        let nextSpace: any = "";
        // 需要处理只有一行的情况，否则会报错，导致显示不出来。
        if (this.lineData.length > 1) {
          nextSpace =
            (this.lineData[index + 1]["标注"].length * this.oneLineBiaozhuH +
              this.defaultTop +
              this.fontSize) /
            2;
        } else {
          nextSpace =
            (this.oneLineBiaozhuH + this.defaultTop + this.fontSize) / 2;
        }

        ele.top = top;

        ele.range = [0, top + nextSpace];
      } else if (index == this.lineData.length - 1) {
        const before: any = this.lineData[index - 1];
        const top: any =
          before.top +
          this.defaultTop +
          this.fontSize +
          ele["标注"].length * this.oneLineBiaozhuH;
        ele.top = top;
        ele.range = [before.range[1], top + 40];
      } else {
        const before: any = this.lineData[index - 1];
        const top: any =
          before.top +
          this.defaultTop +
          this.fontSize +
          ele["标注"].length * this.oneLineBiaozhuH;
        const nextSpace: any =
          (this.lineData[index + 1]["标注"].length * this.oneLineBiaozhuH +
            this.defaultTop +
            this.fontSize) /
          2;
        ele.top = top;
        ele.range = [before.range[1], top + nextSpace];
      }
    });
    this.contentHeight = this.lineData[this.lineData.length - 1].top + 40;
  }
  // 渲染文本内容
  private createText() {
    const element: any = document.getElementById("texts2");
    // 渲染文本内容
    this.strArrData.forEach((ele: any, i: any) => {
      if (ele.text == this.lineicon) {
        return;
      }
      drawText(
        element,
        ele.startX,
        this.lineData[ele.line - 1].top,
        ele.text,
        this.fontSize,
        "#333"
      );
    });
  }
  // 清除所有绘图,有参数是清除某部分
  private clearAll(val?: any) {
    const biaozhuDom: any = document.getElementById("biaozhu2");
    const selectDom: any = document.getElementById("select2");
    const textsDom: any = document.getElementById("texts2");
    const highlightDom: any = document.getElementById("highlight2");

    const biaozhuChild: any = biaozhuDom.childNodes;
    const selectChild: any = selectDom.childNodes;
    const textsChild: any = textsDom.childNodes;
    const highlightChild: any = highlightDom.childNodes;

    let domArr: any = [biaozhuDom, selectDom, textsDom, highlightDom];
    let nodeArr: any = [biaozhuChild, selectChild, textsChild, highlightChild];
    nodeArr.forEach((nodes: any, index: any) => {
      if (nodes && nodes.length > 0) {
        for (var i = nodes.length - 1; i >= 0; i--) {
          domArr[index].removeChild(nodes[i]);
        }
      }
    });
  }
  private createContent() {
    // 需要重新绘制的时候需要清除之前的所有路径
    this.clearAll();
    // 生成单个字符串的字符串数组
    this.getStrArr();
    // 生成行数据
    this.getLineDate();
    // 画标注任务
    // this.drawRenwu();
    this.createText();
    this.drawHighlight();
  }
  private drawRenwu() {
    if (this.renwuData.length == 0) {
      return;
    }
    this.renwuData.forEach((ele: any) => {
      const dom: any = document.getElementById("biaozhu2");
      const arr: any = this.strArrData.slice(ele.from_index, ele.to_index + 1);
      arr.forEach((d: any) => {
        const x: any = d.startX - 2;
        const y: any = this.lineData[d.line - 1].top - this.fontSize + 2;
        const width: any = d.width + 2;
        drawBg(dom, x, y, width, this.fontSize + 2, "", "#F4B479");
      });
    });
  }
  private addBiaozhu() {
    if (!this.dialogData["任务名称"]) {
      this.$message.warning("请填写任务名称！");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      // 专题类型: this.filter["专题类型"],
      专题类型列表: [],
      原文库: "古籍",
      book_id: this.data.book_id,
      切分方式: this.filter["切分方式"],
      任务名称: this.dialogData["任务名称"],
      分类: this.dialogData["分类"],
      标注者id: this.dialogData["标注员"],
      book_content_id: this.data.title_id,
      text: this.dialogData.text,
      from_index: this.dialogData.index[0],
      to_index: this.dialogData.index[1],
      // medcase_id: this.filter.medcase_id,
      entity_id: this.filter.medcase_id,
    };
    if (this.filter["专题类型"] && this.filter["专题类型"].length > 0) {
      this.filter["专题类型"].forEach((ele: any) => {
        params["专题类型列表"].push({
          专题类型: ele.name,
          entity_id: ele.entity,
        });
      });
    }
    if (this.filter["专题类型"] == "诊次") {
      params["诊次"] = this.filter["诊次"];
    }
    createRenwu(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("新增成功");
        this.ifShowBiaozhuDialog = false;
        this.$emit("refresh");
      })
      .catch(() => {
        loading.close();
      });
  }
  private remoteFenleiMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetCategory(this, params).then((res: any) => {
      this.fenleis = res;
    });
  }
  private remoteBiaozhuyuanMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    GetBiaozhuyuan(this, params).then((res: any) => {
      this.biaozhuyuans = res;
    });
  }
  private remoteNameMethod(val: any) {
    this.dialogData["任务名称"] = val;
    this.dialogData.id = "";
    const params: any = {
      params: {
        search: val,
      },
    };
    GetRenwuOption(this, params).then((res: any) => {
      this.renwus = res;
    });
  }
  private nameChange(e: any) {
    this.dialogData["任务名称"] = e.name;
    this.dialogData.id = e._id;
    this.dialogData["标注员"] = e.user_id;
    this.dialogData["分类"] = e.category;
    this.$forceUpdate();
  }
  private goBack() {
    this.$router.push("/main/mark/guanli/list");
  }
  private drawHighlight() {
    const indexs: any = this.findWordIndicesOld(
      this.data.content,
      this.searchData["检索内容"]
    );
    // 画高亮文字
    const dom: any = document.getElementById("highlight2");
    indexs.forEach((ele: any) => {
      // 需要一个字一个字画，要不然这里碰到换行会有问题
      const arr = this.strArrData.slice(
        ele,
        ele + this.searchData["检索内容"].length
      );
      arr.forEach((d: any) => {
        const x: any = d.startX - 2;
        const y: any = this.lineData[d.line - 1].top - this.fontSize + 2;
        const width: any = d.width + 2;
        drawBg(dom, x, y, width, this.fontSize + 2, "", "#F4FF25");
      });
    });
  }
  private findWordIndicesOld(text: any, word: any) {
    const indices = [];
    let index = text.indexOf(word);

    while (index > -1) {
      indices.push(index);
      index = text.indexOf(word, index + word.length);
    }

    return indices;
  }
  private mounted() {
    const W = (this.$refs.biaozhuBox as any).offsetWidth;
    this.W = W - 20;
    if (this.$route.query.search) {
      this.searchData = JSON.parse(this.$route.query.search as any);
    }
    // if (W > 900) {
    //   this.W = W;
    // } else {
    //   this.W = 900;
    // }
  }
}
