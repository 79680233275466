

















































































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { PatchApply, PostApply, AddApply, DeleteApply } from "@/request/common";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private data: any[] = [];
  private ifShowAdd: any = false;
  private showShenhe: any = false;
  private editData: any = {
    申请系统: [],
  };
  private openAdd() {
    this.editData = {
      申请系统: [],
    };
    this.ifShowAdd = true;
  }
  private addSure() {
    if (!this.editData["姓名"]) {
      this.$message.warning("请输入姓名");
      return;
    }
    if (!this.editData["性别"]) {
      this.$message.warning("请输入性别");
      return;
    }
    if (!this.editData["手机号码"]) {
      this.$message.warning("请输入手机号");
      return;
    }
    if (!this.editData["单位"]) {
      this.$message.warning("请输入单位");
      return;
    }
    if (!this.editData["职称"]) {
      this.$message.warning("请输入职称");
      return;
    }
    if (!this.editData["申请系统"] || this.editData["申请系统"].length == 0) {
      this.$message.warning("请选择申请系统");
      return;
    }
    console.log(this.editData);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    AddApply(this, this.editData, loading).then((res: any) => {
      this.$message.warning("保存成功");
      this.ifShowAdd = false;
      this.search();
    });
  }
  private deleteOne(item: any) {
    this.$confirm("确定删除么？", "删除", {
      customClass: "commonConfirm",
    }).then((res: any) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      DeleteApply(this, item.id, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("删除成功");
          this.search();
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  private openShenhe(item: any) {
    this.editData = JSON.parse(JSON.stringify(item));
    if (item["状态"] == "待确认") {
      this.editData["状态"] = "";
    }
    this.showShenhe = true;
  }
  private shenheSure() {
    if (!this.editData["状态"]) {
      this.$message.warning("请选择审核结果");
      return;
    }
    const params: any = {
      状态: this.editData["状态"],
      备注: this.editData["备注"],
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PatchApply(this, this.editData.id, params, loading).then((res: any) => {
      this.$message.success("审核成功");
      this.showShenhe = false;
      this.search();
    });
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      状态: this.filters["状态"],
      search: this.filters.search,
      order_field: "创建时间",
      order_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
    };
    localStorage.setItem("shenqingFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostApply(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    const d = localStorage.getItem("shenqingFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
