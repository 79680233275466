

















































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import {
  PatchMaterial,
  PostMaterial,
  AddMaterial,
  DeleteMaterial,
} from "@/request/common";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private data: any[] = [];
  private ifShowEdit: any = false;
  private editData: any = {};
  private read(item: any) {
    window.open(item["下载地址"]);
  }
  private addSure(file: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    AddMaterial(this, form, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("上传成功");
        this.ifShowEdit = false;
        this.search();
      })
      .catch(() => {
        loading.close();
      });
  }
  private editSure(file: any, item: any) {
    const fileObj = file.file;
    // FormData 对象
    const form = new FormData();
    // 文件对象
    form.append("type", "file");
    form.append("file", fileObj);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PatchMaterial(this, item.id, form, loading)
      .then((res: any) => {
        loading.close();
        this.$message.success("上传成功");
        this.ifShowEdit = false;
        this.search();
      })
      .catch(() => {
        loading.close();
      });
  }
  private deleteOne(item: any) {
    this.$confirm("确定删除该记录么？", "删除", {
      customClass: "commonConfirm",
    }).then((res: any) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      DeleteMaterial(this, item.id, loading)
        .then((res: any) => {
          loading.close();
          this.$message.success("删除成功");
          this.search();
        })
        .catch(() => {
          loading.close();
        });
    });
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      状态: this.filters["状态"],
      search: this.filters.search,
      order_field: "更新时间",
      order_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
    };
    localStorage.setItem("shenqingFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostMaterial(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    const d = localStorage.getItem("shenqingFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
