/**
 * @description 处理书籍目录显示
 * @param type
 * @returns 
 */
const BookMuluText = (item: any): any => {
    let text: any = '';
    if (item['一级标题']) {
        text = item['一级标题']
        if (item['二级标题']) {
            text += '(' + item['二级标题'] + ')'
        }
    } else {
        text = item['二级标题']
    }
    return text;
}
/**
 * @description 清除filter
 * @param data{}
 * @returns 
 */
const clearFilter = () => {
    localStorage.setItem("yonghuguanliliFilter", "");
    localStorage.setItem("bentijiaoyanliFilter", "");
    localStorage.setItem("yuanwenguanliliFilter", "");
    localStorage.setItem("ywjyFilter", "");
    localStorage.setItem("ywqfFilter", "");
    localStorage.setItem("stglFilter", "");
    localStorage.setItem("jiaoyanguanliFilter", "");
    localStorage.setItem("renwuguanliFilter", "");
    localStorage.setItem("searchFilter", "");
    localStorage.setItem("shuxinglaiyuanFilter", "");
    localStorage.setItem("biaozhuzhuangtaiFilter", "");
    localStorage.setItem("rengwushenheFilter", "");
    localStorage.setItem("biaozhujieguoFilter", "");
    localStorage.setItem("shenqingFilter", "");
}
/**
 * @description 获取用户相关权限
 * @returns 
 */
const IsShowQuanxian = (tool: any, quanxianData: any) => {
    let result: any = false;
    if (tool == '实体校验') {
        result = quanxianData['实体校验新增概念']
            || quanxianData['实体校验校验']
            || quanxianData['实体校验删除']
            || quanxianData['实体校验查看校验痕迹']
    }
    //  else if (tool == '原文校验') {
    //     result = quanxianData['原文校验校验']
    //         || quanxianData['原文校验查看校验痕迹']
    // } 
    else if (tool == '校验管理') {
        result = quanxianData['校验管理锁定']
            || quanxianData['校验管理查看校验痕迹']
    } else if (tool == '属性来源') {
        result = quanxianData['属性来源新增属性来源']
            || quanxianData['属性来源编辑']
            || quanxianData['属性来源删除'];
    } else if (tool == '用户管理') {
        result = quanxianData['用户管理新增用户']
            || quanxianData['用户管理编辑']
            || quanxianData['用户管理重置密码']
            || quanxianData['用户管理禁用']
    } else if (tool == '角色管理') {
        result = quanxianData['角色管理新增角色']
            || quanxianData['角色管理编辑']
            || quanxianData['角色管理删除']
    } else if (tool == '任务管理') {
        result = quanxianData['任务管理新增任务分类']
            || quanxianData['任务管理新增标注任务']
            || quanxianData['任务管理查看']
            || quanxianData['任务管理分配']
            || quanxianData['任务管理删除']
            || quanxianData['任务管理标注进度']
    } else if (tool == '任务标注') {
        result = quanxianData['任务标注查看']
            || quanxianData['任务标注标注']
            || quanxianData['任务标注标注进度']
    } else if (tool == '任务审核') {
        result = quanxianData['任务审核查看']
            || quanxianData['任务审核标注进度']
    } else if (tool == '标注结果库') {
        result = quanxianData['标注结果库查看']
            || quanxianData['标注结果库标注进度']
    } else if (tool == '进度统计') {
        result = quanxianData['进度统计查看'];
    } else if (tool == 'schema') {
        result = quanxianData['schema语义类型'] ||
            quanxianData['schema属性类型'] ||
            quanxianData['schema关系类型'];
    } else if (tool == '知识图谱') {
        result = quanxianData['知识图谱搜索'];
    } else if (tool == '知识应用') {
        result = quanxianData['知识应用医案']
            || quanxianData['知识应用专题知识库']
    } else {
        result = quanxianData[tool]
    }
    return result

}
/**
 * @description 获取当前时间
 * @param format 时间格式
 * @returns 
 */
const getCurrentTime = (format: any) => {
    const now: any = new Date();
    const year: any = now.getFullYear(); // 得到年份
    let month: any = now.getMonth(); // 得到月份
    let date: any = now.getDate(); // 得到日期
    let hour: any = now.getHours(); // 得到小时
    let minu: any = now.getMinutes(); // 得到分钟
    let sec: any = now.getSeconds(); // 得到秒
    month = month + 1;
    if (month < 10) {
        month = "0" + month;
    }
    if (date < 10) {
        date = "0" + date;
    }
    if (hour < 10) {
        hour = "0" + hour;
    }
    if (minu < 10) {
        minu = "0" + minu;
    }
    if (sec < 10) {
        sec = "0" + sec;
    }
    let time = "";
    if (format == "yyyy") {
        time = year
    } else if (format == "yyyy-MM") {
        time = year + "-" + month
    } else if (format == "yyyy-MM-dd") {
        time = year + "-" + month + "-" + date
    } else if (format == "yyyy-MM-dd HH") {
        time = year + "-" + month + "-" + date + " " + hour
    } else if (format == "yyyy-MM-dd HH:mm") {
        time = year + "-" + month + "-" + date + " " + hour + ":" + minu
    } else if (format == "yyyy-MM-dd HH:mm:ss") {
        time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
    }
    return time;
}
/**
 * @description  把obj数组处理成str数组
 * @param data 需要处理的数据 
 * @param idKey 需要提取的字段字段，不传就是id,
 * @param filterKey 需要过滤的字段
 * @param filterKValue 需要过滤的值
 * @returns 
 */
const GetIdArr = (data: any, idKey: any = "id", filterKey?: any, filterValue?: any) => {
    let arr: any = [];
    if (data.length > 0) {
        data.forEach((item: any) => {
            if (filterKey) {
                if (item[filterKey] == filterValue) {
                    arr.push(item[idKey])
                }

            } else {
                arr.push(item[idKey])
            }

        });
    }

    return arr;
}
export {
    BookMuluText, clearFilter, IsShowQuanxian, getCurrentTime, GetIdArr
};